import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { filter, map, tap } from 'rxjs/operators';
import { AdvancedSearchParams, ApiParcel, HasGekoppeldAppartement } from 'shared-types';
import { Point, GeometryObject, Feature } from 'geojson';
import { saveAs } from 'file-saver';
import { Locatieserver } from '@app/core/interfaces/locatieserver.interface';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { Verblijfsobject } from '../interfaces/verblijfsobject.model';
import { PaginationOptions as NewPaginationOptions } from 'shared-types';
import { UtilService } from './util.service';
import { ExtraParcelInformation } from 'shared-types/parcel.interface';
import { Product } from '../interfaces/order.model';

@Injectable({
  providedIn: CoreModule
})
export class ParcelService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  public encodeGeometryToBase64String(geometry: GeometryObject) {
    const stringifiedGeometry = JSON.stringify(geometry);
    const base64Encoded = btoa(stringifiedGeometry);
    return base64Encoded;
  }

  public list(point: Point) {
    return this.http.get<ApiParcel[]>(this.apiLocation + '/parcel', {
      params: {
        intersects: this.encodeGeometryToBase64String(point)
      }
    });
  }

  public listByMunicipalityAndSection(opts: { gemeente: string, sectie: string }, paginationOptions: NewPaginationOptions) {
    return this.http.get<{ rows: any[], count: number }>(`${this.apiLocation}/parcel/${opts.gemeente}/${opts.sectie}`, {
      params: {
        ...paginationOptions
      }
    }).pipe(
      map(response => {
        return {
          rows: response.rows,
          count: response.count,
          availablePages: [...Array(Math.ceil(response.count / paginationOptions.limit)).keys()].map((page) => page + 1)
        }
      })
    );
  }

  public get(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<ApiParcel>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}`);
  }

  public getRelatedParcels(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<ApiParcel[]>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/related-parcel`);
  }

  public getExtraInformation(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<ExtraParcelInformation>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/extra-information`);
  }

  public getAddressWithinParcel(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<Verblijfsobject[]>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/address`);
  }

  public getAddressWithinParcelWithAppartmentRights(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<
      (Verblijfsobject & HasGekoppeldAppartement)[]
    >(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/address-plus-right`);
  }

  public getAddressRelatedToAppartmentRight(
    gemeente: string,
    sectie: string,
    nummer: number,
    appartementsnummer: number
  ) {
    return this.http.get<Locatieserver.Adres[]>(
      `${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/${appartementsnummer}/address`
    );
  }

  public hasAppartmentRights(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<boolean>(
      `${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/appartment-right-available`
    );
  }

  public listAppartmentRightsWithinParcel(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<any[]>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/appartment-right`);
  }

  public downloadSingleParcel(parcelProperties: { gemeente: string, sectie: string, nummer: number }, params: { fileType: 'dxf' | 'dwg' | 'excel' | 'shapefile' | 'geopackage', includeBGT: boolean, includeBouwvlakken: boolean, includeLuchtfoto: boolean }) {
    return this.http
      .get<any>(`${this.apiLocation}/parcel/${parcelProperties.gemeente}/${parcelProperties.sectie}/${parcelProperties.nummer}/${params.fileType}?includeBgt=${params.includeBGT}&includeBouwvlakken=${params.includeBouwvlakken}&includeLuchtfoto=${params.includeLuchtfoto}`, { responseType: 'blob' as any, observe: 'response' })
      .pipe(
        tap(response => {
          const fileBlob = new Blob([response.body], { type: response.headers.get('content-type') });
          const parsedDispo = this.utilService.extractFilenameAndExtension(response.headers.get('content-disposition'))
          saveAs(fileBlob, `kadastralekaart-enkel-perceel-download.${parsedDispo.extension}`);
        })
      );
  }

  public downloadBounds(bbox: { xmin: number, ymin: number, xmax: number, ymax: number }, params: { fileType: 'dxf' | 'dwg' | 'excel' | 'shapefile' | 'geopackage', maxBboxAreaInDegrees: number, includeBGT: boolean, includeBouwvlakken: boolean, includeLuchtfoto: boolean }) {
    const area = (bbox.xmax - bbox.xmin) * (bbox.ymax - bbox.ymin);
    if (area > params.maxBboxAreaInDegrees) {
      throw new Error('AreaTooLargeError')
    }

    return this.http
      .get<any>(`${this.apiLocation}/parcel/bbox/${bbox.xmin}/${bbox.ymin}/${bbox.xmax}/${bbox.ymax}/${params.fileType}?includeBgt=${params.includeBGT}&includeBouwvlakken=${params.includeBouwvlakken}&includeLuchtfoto=${params.includeLuchtfoto}`, { responseType: 'blob' as any, observe: 'response' })
      .pipe(
        tap(response => {
          const fileBlob = new Blob([response.body], { type: response.headers.get('content-type') });
          const parsedDispo = this.utilService.extractFilenameAndExtension(response.headers.get('content-disposition'))
          saveAs(fileBlob, `kadastralekaart-kaartbeeld-perceel-download.${parsedDispo.extension}`);
        })
      );
  }

  public getParcelsInBbox(bbox: number[]): any {
    return this.http
      .get<any>(`${this.apiLocation}/guidelines?minX=${bbox[0]}&minY=${bbox[1]}&maxX=${bbox[2]}&maxY=${bbox[3]}`)
      .toPromise();
  }

  public exportMeasureLines(features: Feature<any>[], outputFormat: string) {
    return this.http
      .post<any>(`${this.apiLocation}/conversion`, { features, outputFormat: outputFormat }, { responseType: 'blob' as any, observe: 'response' })
      .pipe(
        tap(response => {
          const fileBlob = new Blob([response.body], { type: response.headers.get('content-type') });
          const parsedDispo = this.utilService.extractFilenameAndExtension(response.headers.get('content-disposition'))
          saveAs(fileBlob, `kadastralekaart-metingen.${parsedDispo.extension}`);
        })
      );
  }

  public async getParcelFromCoordinates(lng: number, lat: number) {
    return await this.list({
      type: 'Point',
      coordinates: [lng, lat]
    }).pipe(
      map(parcels => parcels[0]),
      filter(parcel => parcel !== null && parcel !== undefined)
    ).toPromise();
  }


  public advancedParcelSearch(params: AdvancedSearchParams) {
    return this.http.get<{ count: number, rows: { lokaalid: string }[] }>(`${this.apiLocation}/parcel/advanced-search`, {
      params: params as any
    });
  }

  public bulkExport(lokaalIds: string[]) {
    // Make sure that the values are returned in the same order as the input
    return this.http.post<any[]>(`${this.apiLocation}/parcel/advanced-search-by-ids`, {
      ids: lokaalIds
    }).pipe(
      map(parcels => {
        // Make sure that the values are returned in the same order as the input.
        return lokaalIds.map(id => parcels.find(parcel => parcel.lokaalid === id));
      })
    );
  }

  public exportParcelSearchResult(ids: string[], fileType: 'dxf' | 'dwg' | 'excel' | 'geopackage') {
    return this.http.post<any>(`${this.apiLocation}/parcel/advanced-export/${fileType}`, {
      ids: ids
    },
      {
        responseType: 'blob' as any,
        observe: 'response'
      }
    ).pipe(
      tap(response => {
        const fileBlob = new Blob([response.body], { type: response.headers.get('content-type') });
        const parsedDispo = this.utilService.extractFilenameAndExtension(response.headers.get('content-disposition'))
        saveAs(fileBlob, `kadastralekaart-export-${new Date().getTime()}.${parsedDispo.extension}`);
      })
    );
  }

  public getSearchProductOptions(ids: string[], fileType: 'dxf' | 'dwg' | 'excel' | 'geopackage') {
    return this.http.post<Product>(`${this.apiLocation}/parcel/advanced-search-products`, {
      ids: ids,
      fileType: fileType
    },
    );
  }

  public getMaatvoering(gemeente: string, sectie: string, nummer: number) {
    return this.http.get<any>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/maatvoering`);
  }

}
