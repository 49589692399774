export interface Alert {
    readonly id: string;
    readonly userAuth0Id: string | null;
    readonly geometrySource: string;
    readonly geometry: any; // Adjust the type according to your needs
    readonly label: string;
    readonly sourceId: string;
    readonly subjects: any; // Adjust the type according to your needs
    readonly createdAt: Date;
    readonly updatedAt: Date;
}

type AlertCreateParams = Omit<Alert, 'createdAt' | 'updatedAt' | 'id' | 'userAuth0Id'>;

export interface NotificationStateModel {
    alerts: Alert[]   
}

export class CreateAlert {
    static readonly type = '[Alert] Create';
    constructor(public payload: AlertCreateParams) { }
}

export class GetAlert {
    static readonly type = '[Alert] Get';
    constructor(public payload: { id: string }) { }
}

export class ListAlerts {
    static readonly type = '[Alert] GetAll';
}

export class UpdateAlert {
    static readonly type = '[Alert] Update';
    constructor(public payload: { id: string, updatedAlert: Alert }) { }
}

export class DeleteAlert {
    static readonly type = '[Alert] Delete';
    constructor(public payload: { id: string }) { }
}