import { NgModule } from '@angular/core';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UnderscoreToSpace } from '@app/shared/underscore-to-space.pipe';

@NgModule({
  imports: [
    UnderscoreToSpace
  ],
  providers: [
    DatePipe,
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    )
  ]
})
export class CoreModule { }
