import { Injectable } from '@angular/core';



import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreModule } from '../core.module';
import { ListPaymentTerms } from '../states/models/payment-terms.state.model';

@Injectable({
  providedIn: CoreModule
})
export class PaymentTermsResolver  {
  constructor(private store: Store) {}

  async resolve(route: ActivatedRouteSnapshot) {
    await this.store.dispatch(new ListPaymentTerms()).toPromise();
    return
  }
}
