import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError} from 'rxjs/operators';
import { CoreModule } from '@app/core/core.module';
import { Router } from '@angular/router';

@Injectable({
  providedIn: CoreModule
})
export class UnprocessableEntityInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status == 422 && error.error.error === 'BlacklistError') {
          console.log(error);
          // this.zone.run(() => {
            this.router.navigate(['/', 'kan-niet-worden-weergegeven'], {
              queryParams: {
                error: error.error.error
              }
            })
          // })
        }
        throw error;
      })
    );
  }
}
