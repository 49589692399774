import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AhnStatsResponse } from '@app/core/interfaces/ahn-stats.model';

@Injectable({
  providedIn: CoreModule
})
export class AhnService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAhnHeight(parcel: {gemeente: string, sectie: string, perceelnummer: number}) {
    return this.http.get<AhnStatsResponse>(`${this.apiLocation}/parcel/${parcel.gemeente}/${parcel.sectie}/${parcel.perceelnummer}/ahn/ahnStats`);
  }

  public getAhnImageUrl(parcel: {gemeente: string, sectie: string, perceelnummer: number}) {
    return `${this.apiLocation}/parcel/${parcel.gemeente}/${parcel.sectie}/${parcel.perceelnummer}/ahn/image.tiff`
  }

  public getAhnImage(url: string) {
    return this.http.get(url, { responseType: 'arraybuffer' });
  }
}