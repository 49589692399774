import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OrderItem, Product } from '../interfaces/order.model';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { ProductOption } from 'shared-types';

@Injectable({
  providedIn: CoreModule
})
export class ProductService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) {}

  public list() {
    return this.http.get<Product[]>(`${this.apiLocation}/product`);
  }

  public listProductOptionsForParcel(gemeente: string, sectie: string, nummer: string) {
    return this.http.get<Product[]>(`${this.apiLocation}/parcel/${gemeente}/${sectie}/${nummer}/product`);
  }

  public listProductsOptionsForAddress(nummeraanduiding: string) {
    return this.http.get<Product[]>(`${this.apiLocation}/address/${nummeraanduiding}/product`);
  }

  public listProductsOptions(productId: number, q: string) {
    return this.http.get<ProductOption[]>(`${this.apiLocation}/product/${productId}/option`, {
      params: {
        q
      }
    })
  }

  public listRecommendedProducts(orderItem: OrderItem) {
    return this.http.get<Product[]>(`${this.apiLocation}/product/${orderItem.productId}/recommended`, {
      params: {
        selectedOptionId: orderItem.data.selectedOption.id,
        selectedOptionType: orderItem.data.selectedOption.type,
        selectedOptionLabel: orderItem.data.selectedOption.label,
      }
    })
  }

}
