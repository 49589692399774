import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { FAQService } from '@app/pages/faq/faq.service';

@Injectable({
  providedIn: CoreModule
})
export class FaqItemsResolver  {
  constructor(
    private faqService: FAQService
    ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.faqService.faqContent$;
  }
}
