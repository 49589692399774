export interface GpsStateModel {
    coords: any;
    timestamp: number;
}


export class SetGpsLocation {
    static readonly type = '[GPS] SetLocation';
    constructor(public payload: {
        coords: any
        timestamp: number;
    }) { }
}

export class ClearGpsLocation {
    static readonly type = '[GPS] ClearLocation';
    constructor() { }
}
