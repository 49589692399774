import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { AuthState } from '../states/auth.state';

@Injectable({
  providedIn: CoreModule
})
export class LoggedInGuard {
  constructor(
    private store: Store,
    private router: Router
  ) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const loginHint = next.queryParams['loginHint'];

    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    if (isAuthenticated === false) {

      const targetPage = loginHint === 'signup' ? '/account/aanmaken' : '/account/inloggen';
      return this.router.navigate([targetPage], { queryParams: { returnUrl: state.url } });
    }

    return isAuthenticated;
  }
}
