import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { AppStateModel, RemoveLastUrl, SetBannerClose, SetCurrentUrl, SetGoogleAdsConsent, SetLastRead } from "./models/app.state.model";
import dayjs from "dayjs";
import { StateNames } from "../common/state-names.enum";

const MAX_URL_HISTORY_LENGTH = 5;
@State<AppStateModel>({
  name: StateNames.appv3,
  defaults: {
    googleAdsConsent: false,
    openConsentWindow: true,
    urlHistory: [],
    lastRead: dayjs().subtract(1, 'week').toDate(),
    bannerClose: dayjs().subtract(3, 'year').toDate().getTime(),
  }
})
@Injectable()
export class AppState {

  @Selector([AppState])
  static bannerClose(state: AppStateModel) {
    return state.bannerClose;
  }

  @Selector([AppState])
  static showUpdateBanner(state: AppStateModel) {
    const activeDate = new Date("2024-10-25");
    const bannerActiveFrom = dayjs(activeDate);

    const currentDate = new Date();
    if (state.bannerClose > bannerActiveFrom.toDate().getTime()) {
      return false;
    } else {
      return currentDate.getTime() < bannerActiveFrom.add(2, 'week').toDate().getTime();
    }
  }

  @Selector([AppState])
  static googleAdsConstent(state: AppStateModel) {
    return state.googleAdsConsent;
  }

  @Selector([AppState])
  static lastRead(state: AppStateModel) {
    return state.lastRead;
  }

  @Selector([AppState])
  static openConsentWindow(state: AppStateModel) {
    return state.openConsentWindow;
  }

  @Selector([AppState])
  static previousRoute(state: AppStateModel): string {
    return state.urlHistory[state.urlHistory.length - 2] || '/';
  }

  constructor() { }

  @Action(SetBannerClose)
  setBannerClose(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      bannerClose: new Date().getTime()
    });
  }

  @Action(SetLastRead)
  setLastRead(ctx: StateContext<AppStateModel>, action: SetLastRead) {
    ctx.patchState({
      lastRead: new Date()
    });
  }

  @Action(SetGoogleAdsConsent)
  setGoogleAdsConsent(ctx: StateContext<AppStateModel>, action: SetGoogleAdsConsent) {
    ctx.patchState({
      googleAdsConsent: action.payload.adConsentGiven,
      openConsentWindow: false
    });
  }
  
  @Action(SetCurrentUrl)
  setCurrentUrl(ctx: StateContext<AppStateModel>, action: SetCurrentUrl) {
    const urls = ctx.getState().urlHistory || [];
    if (urls[urls.length - 1] !== action.payload.currentUrl) {

      // urls.

      ctx.patchState({
        urlHistory: [...urls, action.payload.currentUrl]
      });
    }
    return;
  }

  @Action(RemoveLastUrl)
  removeLastUrl(ctx: StateContext<AppStateModel>, action: RemoveLastUrl) {
    const urls = ctx.getState().urlHistory;
    return ctx.patchState({
      urlHistory: [...urls.slice(-MAX_URL_HISTORY_LENGTH).slice(0, -1)]
    });
  }

}

