import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { Bronhouder } from '../interfaces/bronhouder.interface';
import { pluck } from 'rxjs/operators';
import { BgtBedekking } from '../interfaces/bgt-bedekking.interface';

@Injectable({
  providedIn: CoreModule
})
export class BgtService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) {}

  public listBronhouders(parcel: {gemeente: string, sectie: string, perceelnummer: number}) {
    return this.http.get<{type: 'string', features: Bronhouder[]}>(`${this.apiLocation}/parcel/${parcel.gemeente}/${parcel.sectie}/${parcel.perceelnummer}/bronhouders`).pipe(
      pluck('features')
    );
  }

  public listBedekkingen(parcel: {gemeente: string, sectie: string, perceelnummer: number}) {
    return this.http.get<BgtBedekking[]>(`${this.apiLocation}/parcel/${parcel.gemeente}/${parcel.sectie}/${parcel.perceelnummer}/bedekkingen`);
  }
}
