import { Injectable, Renderer2, Inject } from '@angular/core';

import { CoreModule } from '../core.module';

import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: CoreModule
})
export class DatHuisService {
    private subscriptions: Subscription[] = [];
    public renderer: Renderer2;
    public enabled$ = new BehaviorSubject(false);

    constructor(private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
    ) {
    }

    public enable(renderer2: Renderer2) {
        this.renderer = renderer2;
        this.subscriptions.push(
            this.logRouteParameters()
        );
    }

    public getMaterialDialogOverridePosition() {
        return {
            position: {
                top: '200px'
            }
        }
    }

    private logRouteParameters() {
        return this.route.queryParams.subscribe(params => {
            if (params.accountId !== undefined && params.accountId !== null && params.accountId !== '') {
                sessionStorage.setItem('accountId', params.accountId);
                this.loadJsScript(this.renderer, `assets/third-party-scripts/dathuis.js`);
                this.enabled$.next(true)
            }
        })
    }

    public getMetadata() {
        const accountId = sessionStorage.getItem('accountId');
        if (accountId) {
            return {
                dathuis: { accountId }
            };
        }
        return {};
    }

    public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        renderer.appendChild(this.document.body, script);
        return script;
    }

}
