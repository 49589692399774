import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { PaymentTerm } from "../interfaces/payment-term.model";
import { SubscriptionService } from "../services/subscription.service";
import { ListPaymentTerms } from "./models/payment-terms.state.model";

@State<PaymentTerm[]>({
    name: 'paymentTerms',
    defaults: []
})
@Injectable()
export class PaymentTermsState {
    // Return all payment terms
    @Selector([PaymentTermsState])
    static paymentTerms(state: PaymentTerm[]): PaymentTerm[] {
        return state.map(paymentTerm => {
            return {
                ...paymentTerm,
                displayName: getDisplayName(paymentTerm.name)
            }
        });
    }

    // Dynamic selector notation for returning only 1 payment term
    static getPaymentTermByName(name: string) {
        return createSelector(
            [PaymentTermsState.paymentTerms],
            (paymentTerms: PaymentTerm[]) => {
                return paymentTerms.find(paymentTerm => paymentTerm.name === name);
            }
        );
    }

    constructor(private subscriptionService: SubscriptionService) { }

    @Action(ListPaymentTerms)
    listPaymentTerms(ctx: StateContext<PaymentTerm[]>) {
        return this.subscriptionService.listPaymentTerms().pipe(
            tap((paymentTerms) => {
                ctx.setState(paymentTerms);
            })
        );
    }


}

function getDisplayName(paymentTermName: string) {
    const displayNameMap =  {
        ['monthly']: 'Direct lid worden',
        ['yearly']: 'Jaarlidmaatschap'
    };

    return displayNameMap[paymentTermName];
}

