import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { SpinService } from '../../core/services/spin.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-spin',
  template: `
  @if (showSpinner === true) {
    <div class="lds-dual-ring"></div>
  }
 `,
  standalone: true,
  imports: [AsyncPipe],
  styles: [
    `
      .lds-dual-ring {
      display: inline-block;
    }

    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      border: 2px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinComponent implements OnDestroy {
  public showSpinner = false;
  public showSpinner$ = this.spinService.showSpinner$.subscribe((value) => {
    this.showSpinner = value;
    this.cd.detectChanges();
  });
  constructor(private spinService: SpinService, private cd: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.showSpinner$?.unsubscribe();
  }
}
