import { Product } from "@app/core/interfaces/order.model";

export const PRODUCT_SORT_OPTIONS = [
    'Populariteit',
    'Prijs (laag naar hoog)',
    'Prijs (hoog naar laag)',
    'Naam (A-Z)',
    'Naam (Z-A)'
] as const;

export type SortOption = typeof PRODUCT_SORT_OPTIONS[number];

export interface ProductsStateModel {
    products: Product[];
    activeProductCategory: string | null;
    sortOptions: typeof PRODUCT_SORT_OPTIONS;
    activeSortOption: typeof PRODUCT_SORT_OPTIONS[number];
}

export class ListProducts {
    static readonly type = '[Products] List';
    constructor() {
    }
}

export class SetActiveProductCategory {
    static readonly type = '[Products] SetActiveCategory';
    constructor(public payload: { category: string | null }) {
    }
}

export class SetSortOption {
    static readonly type = '[Products] SetSortOrder';
    constructor(public payload: { option: SortOption }) {
    }
}

