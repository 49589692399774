<div class="headerspacer"></div>

<div class="header" [ngStyle]="{'top.px': this.headerDisplacement - this.initialHeaderHeight}">

  @if ((hideItems$ | async) === false) {

  @if ((isMobile$ | async)=== true ) {
  <span (click)="toggleShowItem('menu')" class="header__button header__button--menu" aria-label="Menu">
    <svg height="16" width="16" class="icon">
      <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-menu"></use>
    </svg>
  </span>
  }
  }

  <a routerLink="/" class="header__logolink">
    <h1 class="header__logo">KadastraleKaart.com</h1>
  </a>

  @if ((hideItems$ | async) === false) {
  <div class="header__buttons header__buttons--left">
    @if ((isPro$ | async) === false) {
    <a routerLink="/upgrade" class="header__button">
      Upgrade
    </a>
    }
    <span (click)="toggleShowItem('mainDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem === 'mainDesktopNavMenu'">
      Kaarten
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-chevron-down"></use>
      </svg>
    </span>

    <a routerLink="/producten" class="header__button">
      Webwinkel
    </a>

    <span (click)="toggleShowItem('moreDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem === 'moreDesktopNavMenu'">
      Meer
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-chevron-down"></use>
      </svg>
    </span>

  </div>

  }

  <app-spin class="header__spinner"> </app-spin>

  <div class="header__buttons header__buttons--right">
    @if((hideItems$ | async) === false) {
    <span class="header__button header__button--account" (click)="toggleShowItem(null)">
      <app-account-button> </app-account-button>
    </span>
    }

    @if((hideItems$ | async) === false) {
    <a (click)="toggleShowItem('search')" class="header__button header__button--search">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-search"></use>
      </svg>
    </a>
    }

    @if((hideItems$ | async) === false) {
    <a [class.header__button--cart--empty]="(itemsInCart$ | async) === 0"
      [routerLink]="['/', 'afrekenen', 'besteloverzicht']" class="header__button header__button--cart"
      aria-label="Winkelwagen">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart"></use>
      </svg>
      @if (itemsInCart$ | async) {
      <span class="header__button__counter">
        {{ itemsInCart$ | async }}
      </span>
      }

    </a>
    }
  </div>
</div>

@if (showItem === 'menu') {
<div (click)="toggleShowItem(null)" class="menu-container-backdrop"></div>
<div class="menu-container">
  <div class="menu-container__header">
    <h1 class="menu-container__header__logo">KadastraleKaart.com</h1>
    <div class="menu-container__close" (click)="toggleShowItem(null)">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-10.svg#icon-x"></use>
      </svg>
    </div>
  </div>

  @defer (on viewport; prefetch on idle) {
  <app-menu menuType="headermenu-mobile" [menuItems]="mobileMenuItems$ | async" [showDisclaimer]="false"> </app-menu>
  } @loading (after 150ms) {
  <div>
    @for (i of [].constructor(5);track i) {
    <div class="menu-container__loading">
      <div class="menu-container__loading__icon"></div>
      <div class="menu-container__loading__label">
        <div class="menu-container__loading__label__main"></div>
        <div class="menu-container__loading__label__subtitle"></div>
      </div>
    </div>
    }
  </div>
  }
  @placeholder {
  <div></div>
  }
</div>
}
@if (showItem === 'mainDesktopNavMenu') {
@defer( when showItem === 'mainDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-maps" [menuItems]="mainDesktopNavMenu$ | async"
    [showDisclaimer]="false"></app-menu>
</app-header-menutray>
}
}

@if (showItem === 'moreDesktopNavMenu') {
@defer (when showItem === 'moreDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-more" [menuItems]="moreDesktopNavMenu"
    [showDisclaimer]="false"></app-menu>
</app-header-menutray>
}
}

@if (showItem === 'search') {
@defer (when showItem === 'search'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)" contentPosition="center">
  <app-header-search style="flex: 1 1 100%"></app-header-search>
</app-header-menutray>
}
}


<!-- TAKE NOTE: Test dit met Chrome op responsive mode en iPhone SE, en zorg dat deze tekst slechts een regel is. Anders is gans de interface kaput -->

@if (showUpdateBanner$ | async; as update) {
<div class="bannernotification" [ngStyle]="{'top.px': this.headerDisplacement  }">
  <div class="bannernotification__nieuw">NIEUW</div>
  @if (update$ | async; as update) {
  <a (click)="close()" routerLink="/{{update.full_slug}}" class="bannernotification__link">
    {{update.name}}
  </a>
  }
  <svg height="20" width="20" class="icon bannernotification__close" (click)="close()">
    <use xlink:href="assets/icons/svg/all_svg_icons-9.svg#icon-x"></use>
  </svg>
</div>
}