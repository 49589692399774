import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";

import { ClearGpsLocation, GpsStateModel, SetGpsLocation } from "./models/gps.state.model";

@State<GpsStateModel>({
    name: 'gps',
    defaults: { 
        coords: null,
        timestamp: null
    }
        
})
@Injectable()
export class GpsState {
    @Selector([GpsState])
    static location(state: GpsStateModel) {
        return state;
    }

    constructor() { }

    @Action(SetGpsLocation) 
    setGpsLocation(ctx: StateContext<GpsStateModel>, action: SetGpsLocation) {
        ctx.setState(action.payload);
    }

    @Action(ClearGpsLocation)
    clearGpsLocation(ctx: StateContext<GpsStateModel>, action: ClearGpsLocation) {
        ctx.setState({ 
            coords: null,
            timestamp: null
        });
    }
}

