import { SeoService } from '@app/core/services/seo.service';
import { Component, OnInit, Renderer2, Inject, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-structured-data',
  template: '<div class="structured-data" ></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [
    `
  .structured-data {
    height: 0;
    overflow: hidden;
    color: #f3f3f3;
  }
    `

  ]
})
export class StructuredDataComponent implements OnInit, OnDestroy {
  public scriptTag;
  private subscriptions: Subscription[] = [];

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private seo: SeoService) {
    this.changeDetectorRef.detach();
  }


  ngOnInit() {
    this.scriptTag = this.renderer2.createElement('script');
    this.scriptTag.type = 'application/ld+json';
    this.renderer2.appendChild(this.document.body, this.scriptTag);

    const sub = this.seo.structuredData.subscribe(sdObject => {
      this.updateScriptTag({

        "@context": "http://schema.org",
        "@graph": sdObject

      });
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  private updateScriptTag(linkedData: any) {
    this.renderer2.removeChild(this.document.body, this.scriptTag);
    this.scriptTag.text = JSON.stringify(linkedData);
    this.renderer2.appendChild(this.document.body, this.scriptTag);
  }
}
