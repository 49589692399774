
export interface AppStateModel {
  googleAdsConsent: boolean;
  openConsentWindow: boolean;
  urlHistory: string[];
  lastRead: Date;
  bannerClose: number;
}

export class SetGoogleAdsConsent {
  static readonly type = '[App] SetGoogleAdsConsent';
  constructor(public payload: {
    adConsentGiven: boolean
  }) { }
}

export class SetCurrentUrl {
  static readonly type = '[App] SetCurrentUrl';
  constructor(public payload: { currentUrl: string }) { }
}

export class RemoveLastUrl {
  static readonly type = '[App] RemoveLastUrl';
  constructor() { }
}

export class SetLastRead {
  static readonly type = '[App] SetLastRead';
  constructor() { }
}

export class SetBannerClose {
  static readonly type = '[App] SetBannerClose';
  constructor() { }
}