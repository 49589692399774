import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { CoreModule } from '@app/core/core.module';

@Injectable({
    providedIn: CoreModule
})
export class DsoInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Add tokens if requests are made to our own backend.
        if (request.url.includes(environment.dso.originalDomain)) {
            const proxyReq = request.clone({
                url: request.url.replace(environment.dso.originalDomain, environment.dso.proxyDomain)
            })
            return next.handle(proxyReq);
        } else if (request.url.includes(environment.rp.originalDomain)) {
            const proxyReq = request.clone({
                url: request.url.replace(environment.rp.originalDomain, environment.rp.proxyDomain)
            })
            return next.handle(proxyReq);
        } else {
            return next.handle(request);
        }
    }
}
