import { Locatieserver } from '@app/core/interfaces/locatieserver.interface';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ApiParcel } from 'shared-types';

import { CoreModule } from '../core.module';
import { Verblijfsobject } from '../interfaces/verblijfsobject.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: CoreModule
})
export class UtilService {
  public emailValidatorRegex = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
  public phoneValidatorRegex = /[- +()0-9]{8,}/;

  constructor() { }

  public formatStringForUrlUsage(urlString: string): string {
    return `${urlString}`
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/'/g, '')
      .replace(/\,/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\./g, '')
      .replace(/\//g, '');
  }

  public formatUrlFriendlyAddressName(woonplaatsnaam: string, openbareruimtenaam: string, huisnummer: number): string {
    return `${this.formatStringForUrlUsage(woonplaatsnaam)}-${this.formatStringForUrlUsage(
      openbareruimtenaam
    )}-${this.formatStringForUrlUsage(huisnummer.toString())}`;
  }

  public formatAreaLabel(area: number, roundDigits = true) {
    const hectare = 10000;
    const squareKm = 1000000;
    let areaLabel = '';
    if (area < hectare) {
      if (roundDigits) {
        areaLabel =
          area
            .toFixed(0)
            .toString()
            .replace('.', ',') + ' m2';
      } else {
        areaLabel = area.toString().replace('.', ',') + ' m2';
      }
    } else if (area < squareKm) {
      areaLabel =
        (area / hectare)
          .toFixed(1)
          .toString()
          .replace('.', ',') + ' ha';
    } else {
      areaLabel =
        (area / squareKm)
          .toFixed(2)
          .toString()
          .replace('.', ',') + ' km2';
    }
    return areaLabel;
  }

  public formatDistanceLabel(distance: number) {
    const km = 1000;
    let distanceLabel = '';
    if (distance < km) {
      distanceLabel =
      distance
        .toFixed(0)
        .toString()
        .replace('.', ',') + ' m.';
    } else {
      distanceLabel =
      (distance / km)
        .toFixed(1)
        .toString()
        .replace('.', ',') + ' km.';
    }
    return distanceLabel
  }

  public formatAddressNameForUrl(address: Verblijfsobject | Locatieserver.Adres) {
    return this.formatUrlFriendlyAddressName(address.woonplaatsnaam, address.straatnaam, address.huisnummer);
  }

  public formatAddressInfocardUrl(address: Verblijfsobject) {
    return ['/', 'adres', this.formatAddressNameForUrl(address), address.nummeraanduidingidentificatie];
  }

  public splitKadastraleAanduidingString(parcelId: string) {
    if (parcelId.includes('-') === false) {
      /* Without '-', so we need to do Regexes to get the right parts of the ID */
      const municipality = parcelId.slice(0, 5);
      const sectionAndNumber = parcelId.slice(5, parcelId.length);
      const section = sectionAndNumber.match(/([A-z]*)(?=[0-9])/)[0]; // Match all characters that are followed by numbers (dus het sectienummer.)
      const number = sectionAndNumber.match(/\d+/g)[0]; // Match all numbers
      return {
        municipality,
        section,
        number
      };
    } else {
      /* With '-' its much easier*/
      const [municipality, section, number] = parcelId.split('-');

      return {
        municipality,
        section,
        number
      };
    }

  }

  public getKadastraleAanduiding(parcel: ApiParcel) {
    const gemeentecode = parcel.gemeente;
    const sectie = parcel.sectie;
    const nummer = parcel.perceelnummer;
    return `${gemeentecode} ${sectie} ${this.padNumber(nummer, 4)}`;
  }

  public getKadastraleAanduidingByParcelId(parcelId: string) {
    const { municipality, section, number } = this.splitKadastraleAanduidingString(parcelId);
    return `${municipality} ${section} ${number}`;
  }

  private padNumber(n: any, p: number, c?: string) {
    const pad_char = typeof c !== 'undefined' ? c : '0';
    const pad = new Array(1 + p).join(pad_char);
    return (pad + n).slice(-pad.length);
  }

  public stringArrayToLegibleStringList(array: string[]) {
    return array.length > 1 ? `${array.slice(0, -1).join(', ')} en ${array.slice(-1)}` : array[0];
  }

  // * Validator functions ----
  public minLengthArray(min: number) {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value.length >= min) {
        return null;
      }

      return { 'minLengthArray': { valid: false } };
    }
  }

  public minMax(control: AbstractControl) {
    const noMinVal = (control?.value?.minFilterValue === null || control?.value?.minFilterValue === undefined || control?.value?.minFilterValue === '');
    const noMaxVal = (control?.value?.maxFilterValue === null || control?.value?.maxFilterValue === undefined || control?.value?.minFilterValue === '');
    if (noMinVal && noMaxVal) {
      return { 'minMax': { valid: false } };
    }

    if ((control.value.minFilterValue > control.value.maxFilterValue) && control.value.maxFilterValue !== null) {
      return { 'minMax': { valid: false } };;
    }

    return null;
  }

  public booleanFilterValue(control: AbstractControl) {
    if (control.value.booleanFilterValue !== null && control.value.booleanFilterValue !== undefined) {
      return null;
    }
    return { 'booleanFilterValue': { valid: false } };
  }

  public determineShowWarning(currentZoom: number, minZoom: number): boolean {
    let show = false;
    if (currentZoom < minZoom) {
      show = true;
    }
    return show;
  }

  public objectValuesAsString(obj: any): string {
    return Object.values(obj).reduce((prev, curr) => {
      if (typeof curr !== 'object' && curr !== null) {
        return prev += ` ${curr}`;
      } else {
        return prev;
      }
    }, '') as string;
  }

  public findLatestStatistics(yearObject: {}) {
    const stats = Object.values(yearObject).reverse();
    for (const statistic of stats) {
      if (statistic || statistic === 0) {
        return statistic as number;
      }
    }
    return undefined;
  }

  public getAPIDocsUrl() {
    return `${environment.apiDomain}/public/v1/docs`
  }

  public extractFilenameAndExtension(contentDisposition: string): { filename: string | null, extension: string | null } {
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?(?:\.\w+)?(?:$|;)/i;
    const match = contentDisposition.match(regex);

    if (!match) {
        return { filename: null, extension: null };
    }

    const filenameWithExtension = match[1].trim();
    const lastDotIndex = filenameWithExtension.lastIndexOf('.');

    let filename, extension;

    if (lastDotIndex !== -1) {
        filename = filenameWithExtension.substring(0, lastDotIndex);
        extension = filenameWithExtension.substring(lastDotIndex + 1);
    } else {
        filename = filenameWithExtension;
        extension = null;
    }

    return { filename, extension };
}
}
