import { Injectable } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { ProductTypeNames } from '@app/core/interfaces/order.model';
import { firstValueFrom, from, map,  shareReplay } from 'rxjs';

@Injectable({
  providedIn: CoreModule
})
export class FAQService {

  public faqContent$ = from(import('./faq.content')).pipe(
    map(module => module.faqContent),
    shareReplay()
  );

  constructor() { }

  public async getFaqItemsByProductTypeName(productTypeName: ProductTypeNames) {

    const faqContent = await firstValueFrom(this.faqContent$);
    return faqContent.filter(faqItem => faqItem.tags.includes(productTypeName));
  }

}
