import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { SubscriptionType } from '../interfaces/subscription-type.model';

interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user_id: string;
    roles: any[];
    permissions: string[];
}


@Injectable({
    providedIn: CoreModule
})
export class AuthService {
    private apiLocation = environment.apiDomain;
    private apiVersion = environment.apiUrl;
    constructor(private http: HttpClient, private router: Router) { }

    public login(params: { email: string, password: string }) {
        return this.http.post<LoginResponse>(`${this.apiLocation}/auth/login`, params).pipe(
            catchError((error) => {
                if (error.error.error === 'migrate_password') {
                    throw new Error('MigratePasswordError');
                }
                if (error.error.code === 401) {
                    throw new Error('InvalidAuthDetailsError');
                }
                throw error.error;
            })
        );
    }

    public loginWithGoogle(params: any) {
        return this.http.get<LoginResponse>(`${this.apiLocation}/auth/google/callback`, { params });
    }

    public register(params: { email: string, password: string, newsletter: boolean }) {
        return this.http.post<any>(`${this.apiLocation}/auth/register`, params);
    }

    public getNewAccessToken(body: { refreshToken: string }) {
        return this.http.post<LoginResponse>(`${this.apiLocation}/auth/access-token`, body);
    }

    public checkEmailExists(email: string) {
        return this.http.get<{ exists: boolean }>(`${this.apiLocation}/auth/email-exists`, { params: { email } });
    }

    public goToLogin(returnUrl: string) {
        this.router.navigate(['/account/inloggen', { params: { returnUrl } }]);
    }

    public goToRegister(returnUrl: string) {
        this.router.navigate(['/account/aanmaken', { params: { returnUrl } }]);
    }

    public startPasswordReset(email: string) {
        return this.http.post<any>(`${this.apiLocation}/auth/password-reset`, null, { params: { email } });
    }

    public resetPassword(token: string, password: string) {
        return this.http.post<any>(`${this.apiLocation}/auth/password-reset/${token}`, { password });
    }

    public startEmailVerification(email: string) {
        return this.http.post<any>(`${this.apiLocation}/auth/email-verification`, null, { params: { email } });
    }

    public finishEmailVerification(token: string) {
        return this.http.post<any>(`${this.apiLocation}/auth/email-verification/${token}`, null);
    }

    public hasPermissions(permissions: string[]) {
        return this.http.get<{ hasPermissions: boolean }>(`${this.apiLocation}${this.apiVersion}/permission`, { params: { 
            permissions: permissions.join(',')
        } });
    }

    public getRequiredSubscriptionType(permissionIds: number[]) {
        return this.http.get<SubscriptionType>(`${this.apiLocation}${this.apiVersion}/permission/${permissionIds.join(',')}/subscription-type`);
    }

}
