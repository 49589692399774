import { FileRef } from "@app/core/interfaces/file-ref";

export class FilesStateModel {
    files: {
        [key: string]: FileRef
    };
}

export class ListFiles {
    static readonly type = '[Files] List';
    constructor(public payload: {
        userId: string
    }) { }
}

export class CreateFile {
    static readonly type = '[Files] Create';
    constructor (public payload: {file: FileRef, linkOpts: {parcelId?: string, userAuth0Id?: string}}) { }
}

export class RemoveFile {
    static readonly type = '[Files] Remove';
    constructor (public payload: {fileId: string}) { }
}
