import { BgtBedekking } from "@app/core/interfaces/bgt-bedekking.interface";
import { Bronhouder } from "@app/core/interfaces/bronhouder.interface";
import { Verblijfsobject } from "@app/core/interfaces/verblijfsobject.model";
import { ApiParcel, Omgevingsplan, RuimtelijkPlan, MapboxStyleDocument } from "shared-types"

export class SelectionStateModel {
    parcel: ApiParcel;
    relatedParcels: ApiParcel[];
    addresses: Verblijfsobject[];
    bronhouders: Bronhouder[];
    bedekkingen: BgtBedekking[];
    bestemmingsplannen: any[];
    addressIndexForWozPage: number;
    dso: RuimtelijkPlan | Omgevingsplan;
    dsoStyling: MapboxStyleDocument;
    ahn: string;
}

export class SetAddressIndexForWozPage {
    static readonly type = '[Selection] SetAddressIndexForWozPage';
    constructor(public payload: { index: number }) { }
}

export class SetParcelSelectionById {
    static readonly type = '[Selection] SetParcelById';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class SetParcelSelectionByAddressId {
    static readonly type = '[Selection] SetParcelByAddressId';
    constructor(public payload: { nummeraanduidingid: string }) { }
}

export class SelectAddress {
    static readonly type = '[Selection] SelectAddress';
    constructor(public payload: { nummeraanduidingid: string }) { }
}

export class SetDso {
    static readonly type = '[Selection] SetDso';
    constructor(public payload: { dso: any }) { }
}

export class SetDsoStyling {
    static readonly type = '[Selection] SetDsoStyling';
    constructor(public payload: { dsoStyling: any }) { }
}

export class SetBronhoudersByParcel {
    static readonly type = '[Selection] SetBronhoudersByParcel';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class SetBedekkingenByParcel {
    static readonly type = '[Selection] SetBedekkingenByParcel';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class SetAhnByParcel {
    static readonly type = '[Selection] SetAhnByParcel';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class SelectAddressesWithinParcel {
    static readonly type = '[Selection] SelectAddressesWithinParcel';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class SelectRelatedParcels {
    static readonly type = '[Selection] SelectRelatedParcels';
    constructor(public payload: { gemeente: string, sectie: string, perceelnummer: number }) { }
}

export class UpdateBestemmingsplannenSelection {
    static readonly type = '[Selection] UpdateBestemmingsplannen';
    constructor(public payload: {
        bestemmingsplannen: any[]
    }) { }
}

export class ClearBronhoudersSelection {
    static readonly type = '[Selection] ClearBronhouders';
    constructor() { }
}

export class ClearBedekkingenSelection {
    static readonly type = '[Selection] ClearBedekkingen';
    constructor() { }
}

export class ClearAhnSelection {
    static readonly type = '[Selection] ClearAhn';
    constructor() { }
}

export class ClearPlanSelection {
    static readonly type = '[Selection] ClearPlan';
    constructor() { }
}

export class ClearSelection {
    static readonly type = '[Selection] Clear';
    constructor() { }
}
