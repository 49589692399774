import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';
import { CoreModule } from '../core.module';
import { Alert } from '../states/models/notification.state.model';
import { LatLngBoundsLiteral } from 'leaflet';
import { ChangeAttributes } from 'shared-types';
import { CHANGE_TYPES } from '@app/core/config/change-types';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs';
import dayjs from 'dayjs';

@Injectable({
  providedIn: CoreModule
})
export class AlertService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) { }

  public findAllChanges(bounds: LatLngBoundsLiteral, dateRange: { fromDate: Date, toDate: Date }, types: string[]) {
    return this.http.get<ChangeAttributes[]>(`${this.apiLocation}/change`, {
      params: {
        xmin: bounds[0][1],
        ymin: bounds[0][0],
        xmax: bounds[1][1],
        ymax: bounds[1][0],
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
        types: types
      }
    }).pipe(
      map(changes => changes.map(change => {
        return {
          ...change,
          color: CHANGE_TYPES.find(type => type.category === change.categorie)?.color,
          new: dayjs(change.datum).isAfter(dayjs().subtract(1, 'week'))
        }
      })));
  }

  public findAll() {
    return this.http.get<Alert[]>(`${this.apiLocation}/notification`);
  }

  public create(data: any) {
    return this.http.post<Alert>(`${this.apiLocation}/notification`, data);
  }

  public remove(id: any) {
    return this.http.delete<Alert>(`${this.apiLocation}/notification/${id}`);
  }

  public changeTypesToFormConfig() {
    const formConfig = CHANGE_TYPES.map(changeType => changeType.types)
      .flat()
      .reduce((prev, curr) => {

        return {
          ...prev,
          [curr]: new FormControl(true)
        }
      }, {});

    return formConfig;

  }



}
