import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import {produce} from "immer";
import { map, tap } from "rxjs/operators";
import { UserParcel } from "../interfaces/user-parcel.model";
import { UserService } from '@app/core/services/user.service';
import { AppendUserParcels, CreateUserParcel, ListTags, ListUserParcels, RemoveUserParcel, UpdateUserParcel, UserParcelsStateModel } from "./models/user-parcels.state.model";

@State<UserParcelsStateModel>({
    name: 'userParcel',
    defaults: {
        userParcels: [],
        tags: []
    }
})
@Injectable()
export class UserParcelState {
    // Return userParcels.
    @Selector([UserParcelState])
    static userParcels(state: UserParcelsStateModel) {
        return state.userParcels;
    }

    // Dynamic selector notation for returning only 1 userParcel
    static getUserParcelById(parcelId: string) {
        return createSelector(
            [UserParcelState.userParcels],
            (userParcels: UserParcel[]) => {
                const userParcel = userParcels.find(userParcel => userParcel.parcelId === parcelId);
                return userParcel;
            }
        );
    }

    @Selector([UserParcelState])
    static tags(state: UserParcelsStateModel) {
        return state.tags;
    }


    constructor(private userService: UserService) { }

    @Action(ListUserParcels)
    listUserParcels(ctx: StateContext<UserParcelsStateModel>, action: ListUserParcels) {
        return this.userService.listParcels(action.payload).pipe(
            tap(userParcels => {
                ctx.patchState({ userParcels: userParcels.rows });
            })
        )
    }

    @Action(CreateUserParcel)
    createUserParcel(ctx: StateContext<UserParcelsStateModel>, action: CreateUserParcel) {
        return this.userService.addParcel(action.payload.auth0Id, action.payload.parcel).pipe(
            map(createdParcel => {
                const userParcels = ctx.getState().userParcels;
                ctx.patchState({ userParcels: [...userParcels, createdParcel] });
                return;
            })
        );
    }

    @Action(UpdateUserParcel)
    updateUserParcel(ctx: StateContext<UserParcelsStateModel>, action: UpdateUserParcel) {
        return this.userService.updateParcel(action.payload.parcelId, action.payload.parcel).pipe(
            tap(updatedParcel => {
                const nextState = produce(ctx.getState().userParcels, draft => {
                    const index = draft.findIndex(userParcel => userParcel.parcelId === updatedParcel.parcelId);
                    draft[index] = {
                        ...draft[index],
                        ...updatedParcel
                    };
                });
                ctx.patchState({ userParcels: nextState });
            })
        );
    }

    @Action(RemoveUserParcel)
    removeUserParcel(ctx: StateContext<UserParcelsStateModel>, action: RemoveUserParcel) {
        return this.userService.removeParcel(action.payload.parcelId, action.payload.userAuth0Id).pipe(
            tap(() => {
                const userParcels = ctx.getState().userParcels;
                const filteredParcels = userParcels.filter(userParcel => userParcel.parcelId !== action.payload.parcelId,);
                ctx.patchState({
                    userParcels: filteredParcels
                });
            })
        );
    }

    @Action(AppendUserParcels)
    appendUserParcels(ctx: StateContext<UserParcelsStateModel>, action: AppendUserParcels) {
        return this.userService.listParcels(action.payload).pipe(
            tap(newUserParcels => {
                const currentUserParcels = ctx.getState().userParcels;
                ctx.patchState({
                    userParcels: [
                        ...currentUserParcels,
                        ...newUserParcels.rows
                    ]
                });
            })
        )
    }

    @Action(ListTags)
    listTags(ctx: StateContext<UserParcelsStateModel>, action: ListTags) {
        return this.userService.listTags(action.payload.userAuth0Id).pipe(
            tap(tags => {
                ctx.patchState({ tags: tags });
            })
        );
    }
}



