import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { AuthState } from '../states/auth.state';

@Injectable({
    providedIn: CoreModule
})
export class PermissionsGuard {
    constructor(private router: Router, private store: Store) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

        if (!isAuthenticated) {
            this.router.navigate(['/account/inloggen']);
            return false;
        }
        const permissions: string[] = next.data['requiredPermissions'];

        const hasPermissions = this.store.selectSnapshot(AuthState.hasPermissions(permissions));

        if (!hasPermissions) {
            this.router.navigate([next.data['redirectTo'] || '/account/inloggen']);
        }

        return hasPermissions;
    }
}
