
export interface PropertyDescription {
    name: string;
    alias: string;
}

export interface UserPropertyDescription extends PropertyDescription {
    type: string;
    defaultValue: any
}

export const USER_PROPERTIES: UserPropertyDescription[] = [
    {
        name: 'address',
        alias: 'Adres',
        type: 'string',
        defaultValue: null
    },
    {
        name: 'telephone',
        alias: 'Telefoonnummer',
        type: 'string',
        defaultValue: null
    },
    {
        name: 'owner',
        alias: 'Eigenaar',
        type: 'string',
        defaultValue: null
    },
    {
        name: 'last_contact_moment',
        alias: 'Laatste contactmoment',
        type: 'string',
        defaultValue: null
    },
    {
        name: 'call_back',
        alias: 'Terugbellen',
        type: 'string',
        defaultValue: null
    },
    {
        name: 'remarks',
        alias: 'Opmerkingen',
        type: 'string',
        defaultValue: null
    },
]

export const DEFAULT_USER_PROPERTIES: PropertyDescription[] = [
    {
        alias: 'Kadastrale aanduiding',
        name: 'parcelId',
    },
    {
        alias: 'Kadastrale gemeente',
        name: 'gemeente',
    },
    {
        alias: 'Sectie',
        name: 'kadastrale_sectie',
    },
    {
        alias: 'Perceelnummer',
        name: 'perceelnummer',
    },
    {
        alias: 'Beschrijving',
        name: 'description',
    },
    {
        alias: 'Kleurcode',
        name: 'color',
    },
    {
        alias: 'Datum opgeslagen',
        name: 'createdAt',
    }
]
