const oneFifth = new Date().getTime() % 5 === 0;


export const environment = {
  environmentName: 'production',
  production: true,
  apiUrl: '/api/v1',
  apiDomain: 'https://api.kadastralekaart.com',
  frontendLocation: 'https://kadastralekaart.com/',
  pdokProxyLocation: 'https://service.pdok.nl', // reset to https://pdok.kadastralekaart.com if needed.
  enableGoogleAnalytics: true,
  auth0ClientId: '7A1dNXQwbQxDvyzauAwBdhS3BPQFVX5D',
  googleAnalyticsTrackingId: 'G-08H4BVXCFQ',
  googleAdsTrackingId: 'AW-698173757',
  googleAdsConversionLabel: 'l8HlCIy7iLYBEL2S9cwC',
  googleOptimizeTrackingId: 'OPT-NJH4K63',
  keepShoppingCartOnPurchase: false,
  storyBlokAccessToken: '8g9zisNzjpBjpcFpDJ7PNAtt',
  storyBlokCache: 'memory',
  storyBlokVersion: 'published',
  moneybirdAdministrationId: '306718101122057459',
  rollbar: {
    enableRollbar: true,
    apiKey: '8ab78bc713d344b1ab2e0a17acaaca05'
  },
  vectorTiles: 'https://tiles.kadastralekaart.com',
  rp: {
    originalDomain: 'https://ruimte.omgevingswet.overheid.nl',
    proxyDomain: 'https://dso-rp.kadastralekaart.com',
  },
  dso: {
    originalDomain: 'https://service.omgevingswet.overheid.nl',
    proxyDomain: 'https://dso.kadastralekaart.com',
  },
  useImgix: true,
  imgixDomain: 'https://kadastralekaart.imgix.net/',
  keepShowingBanner: false
};
